import request from '@/utils/request.js'

// 报告列表
export function reportListApi(data) {
  return request({
    url: '/doftec/admin-report/report',
    method: 'post',
    data: data
  })
}

// 报告新增
export function reportAddApi(data) {
  return request({
    url: '/doftec/admin-report/create',
    method: 'post',
    data: data
  })
}

// 报告更新
export function reportUpdateApi(data) {
  return request({
    url: '/doftec/admin-report/update.u',
    method: 'post',
    data: data
  })
}

// 报告详情 根据id查看pdf附件
export function reportDetailApi(params) {
  return request({
    url: '/doftec/admin-report/info',
    method: 'get',
    params: params
  })
}

// 报告删除/修改状态 操作状态：delete 删除 push 推送 download 下载
export function reportDeleteApi(data) {
  return request({
    url: '/doftec/admin-report/action',
    method: 'post',
    data: data
  })
}

// 地市报告列表
export function cityReportListApi(data) {
  return request({
    url: '/doftec/reportCity/list',
    method: 'post',
    data: data
  })
}

// 地市报告修改
export function cityReportUpdateApi(data) {
  return request({
    url: '/doftec/reportCity/update',
    method: 'post',
    data: data
  })
}

// 地市报告添加
export function cityReportAddApi(data) {
  return request({
    url: '/doftec/reportCity/create',
    method: 'post',
    data: data
  })
}

// 地市报告删除 操作状态：delete 删除 push 推送 download 下载
export function cityReportDeleteApi(data) {
  return request({
    url: '/doftec/reportCity/action',
    method: 'post',
    data: data
  })
}

// 地市报告详情
export function cityReportDetailApi(params) {
  return request({
    url: '/doftec/reportCity/info',
    method: 'get',
    params: params
  })
}