<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                label-width="100px"
                size="medium"
                @submit.native.prevent
            >
                <el-form-item label="报告名称" prop="name">
                    <el-input
                        v-model="formInline.name"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="选择推荐" prop="recommend">
                  <el-select v-model="formInline.recommend" placeholder="请选择">
                    <el-option
                      v-for="item in recommendOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="id" label="#" width="55">
                </el-table-column>
                <el-table-column prop="name" label="名称">
                    <template slot-scope="scope">
                        <div style="display:flex;algin-item:center;">
                            <span>{{ scope.row.name }}</span>
                            <img
                                v-if="scope.row.recommend === '1'"
                                style="width: 19px; height: 19px;margin-left:6px"
                                src="../../../assets/images/tip.png"
                            />
                            <span style="color: #409eff">{{
                                scope.row.recommend === '1' ? '推荐' : ''
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="cityName" label="城市"></el-table-column>
                <el-table-column prop="createTime" label="时间">
                    <template slot-scope="scope">{{
                        scope.row.createTime
                    }}</template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)">{{
                            scope.row.recommend === '1'
                                ? '取消推荐'
                                : '设为推荐'
                        }}</el-button>
                        <el-button type="text" @click="previewClick(scope.row)"
                            >预览</el-button
                        >
                        <el-button type="text" @click="downloadClick(scope.row)"
                            >下载</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
    </div>
</template>
<script>
// import FileUploadReport from '@/components/FileUploadReport'
import Pagination from '@/components/Pagination'
import { cityReportListApi, cityReportUpdateApi } from '@/api/reportManagement'
import { getFileUrl } from '@/api/file'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            formLabelAlign: {},
            disabled: false,
            recommendOptions:[{
              label:'是',
              value:'1'
            },{
              label:'否',
              value:'0'
            }]
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.reportListFun()
    },
    methods: {
        async reportListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                orderStr: 'id desc',
            }
            var p = Object.assign(params, this.formInline)
            var res = await cityReportListApi(p)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.reportListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.reportListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.reportListFun(parm.currentPage, parm.pageSize)
        },
        // 设为推荐  1为推荐0为不推荐
        async editClick(row) {
            delete row.createTime
            delete row.publishTime
            row.recommend = row.recommend === '0' ? '1' : '0'
            var params = row
            var res = await cityReportUpdateApi(params)
            if (res.code === 200) {
                if (params.recommend === '1') {
                    this.$message({
                        message: '推荐成功',
                        type: 'success',
                    })
                } else {
                    this.$message({
                        message: '取消推荐成功',
                        type: 'success',
                    })
                }
            } else {
                this.$message({
                    message: res.msg,
                    type: 'error',
                })
            }
            this.reportListFun()
        },

        // 预览
        // 预览
        async previewClick(row) {
            if (row.path) {
                // 获取地址
                var params = {
                    ids: row.path,
                }
                var res = await getFileUrl(params)
                if(res.data.length>0){
                    this.url = 'http://www.xdocin.com/xdoc?_func=to&_format=html&_cache=1&_xdoc=https:'+res.data[0].url
                    window.open(this.url,'_blank')
                }else{
                    this.$message({
                        message: '地址有误，请检查报告文件是否存在',
                        type: 'error',
                    })
                }
              
            } else {
                this.$message({
                    message: '没有上传报告， 无法预览',
                    type: 'error',
                })
            }
        },
        // 下载文件
        async downloadClick(row){
            if (row.path) {
                // 获取地址
                 var params = {
                    ids: row.path,
                }
                var res = await getFileUrl(params)
                if(res.data.length>0){
                    var url = res.data[0].url
                    window.open(url)
                }else{
                    this.$message({
                        message: '地址有误，请检查报告文件是否存在',
                        type: 'error',
                    })
                }
                
            } else {
                this.$message({
                    message: '没有上传报告， 无法预览',
                    type: 'error',
                })
            }
           
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
